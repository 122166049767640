import { createEnv, graphqlPreset, oidcPreset } from '@formic/browser-env';

const env = createEnv('FUSION_OF_', [
  ...graphqlPreset(),
  ...oidcPreset(),
  { name: 'DEFAULT_THEME', defaultValue: '0' },
  { name: 'STATIC_URI', defaultValue: 'https://localhost:5000' },
  { name: 'IS_ES_ENVIRONMENT', defaultValue: 'false' },
  { name: 'REDIRECTION_PROJECTS', defaultValue: '' },
  { name: 'NO_NAV_PROJECTS', defaultValue: '' },
  { name: 'USER_IDLE_PROJECTS', defaultValue: '' },
  { name: 'NO_NAVBUTTON_PROJECTS', defaultValue: '' },
  { name: 'NAVBUTTON_RECORDS_MEDIA_PROJECTS', defaultValue: '' },
  { name: 'HIDE_ERROR_MESSAGES', defaultValue: 'false' },
  { name: 'HONOR_OF1_URLS', defaultValue: 'false' },
]);

export default env;
